/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import useStore from '../../../services/hooks/useStore';
import { api } from '../../../services/utils';
import Filtro from '../../../components/Filtro';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { returnTanque } from '../../../services/utils/propriedade';
import ActionButton from '../../../components/Tabela/ActionButton';
import FormTransferencia from './FormTransferencia';
import Confirmar from '../../../components/Modais/Confirmar';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';
import VisualizarTransferencia from './VisualizarTransferencia';
import EditarTransferencia from './EditarTransferencia';

type State = PageState & {
  filtroRapidoEspecie: SelectRow | null;
  rows: Transferencia[];

  filtroEspecie: SelectRow | null;
  filtroLote: SelectRow | null;
  filtroTanqueOrigem: SelectRow | null;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  selectedTransferencia: Transferencia | null;
  filterUrlArray: string[];
  filterUrlString: string;

  sync: boolean;
};
const Transferencia: React.FC = () => {
  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      rows: [] as any,

      selectedTransferencia: null,
      filtroRapidoEspecie: null,

      filtroEspecie: null,
      filtroLote: null,
      filtroTanqueOrigem: null,
      filtroDataInicio: null,
      filtroDataFim: null,

      filterUrlArray: [],
      filterUrlString: '',

      sync: false,
    }),
  );
  const modalCriarTransferencia = useModal();
  const modalCriarTransferenciaPorTanque = useModal();
  const modalVisualizarTransferencia = useModal();
  const modalEditarTransferencia = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();
  const tableRef: any = React.createRef();
  store.propriedade && store.validarPermissao(permissoes.PRODUCAO_TRANSFERENCIA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PRODUCAO_TRANSFERENCIA_ESCRITA);
  const temPermissaoLeitura = store.checkPermissions(permissoes.PRODUCAO_TRANSFERENCIA_LEITURA);

  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedTransferencia = rowData.transferencia;
                  modalEditarTransferencia.open();
                }
              : undefined
          }
          visualizar={
            temPermissaoLeitura
              ? () => {
                  state.selectedTransferencia = rowData.transferencia;
                  modalVisualizarTransferencia.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedTransferencia = rowData.transferencia;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'tqOrigem',
      title: 'Tanque Origem',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'tqDestino',
      title: 'Tanque Destino',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'especie',
      title: 'Espécie',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'peso',
      title: 'Peso Médio (g)',
      type: 'numeric',
      ...(true && ({ width: 140 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    await syncModel.transferencias();
    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Transferências'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);



  useEffect(() => {
    tableRef && tableRef.current?.onQueryChange();
  }, [tableRef]);

  useEffect(() => {
    if (!state.filtroEspecie) {
      if (state.filtroRapidoEspecie) {
        state.filterUrlString = '';
        state.filterUrlArray = [];
        state.filterUrlArray.push(`&especie_explorada=${state.filtroRapidoEspecie.value}`);
        state.filterUrlString = state.filterUrlArray.join('');
        store.transferencias.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
      }
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoEspecie]);

  async function filtrar(): Promise<void> {
    if (
      state.filtroEspecie ||
      state.filtroLote ||
      state.filtroTanqueOrigem ||
      state.filtroDataInicio ||
      state.filtroDataFim
    ) {
      store.toggleLoader();

      if (state.filtroEspecie) {
        state.filterUrlArray.push(`&especie_explorada=${state.filtroEspecie!.value}`);
      } else if (state.filtroLote) {
        state.filterUrlArray.push(`&lote=${state.filtroLote!.value}`);
      } else if (state.filtroTanqueOrigem) {
        state.filterUrlArray.push(`&tanque=${state.filtroTanqueOrigem!.value}`);
      } else if (state.filtroDataInicio) {
        const strDateInicio = Formatter.dateToString(state.filtroDataInicio);
        const requestDateInicio = strDateInicio.split(' ')[0];
        state.filterUrlArray.push(`&data_inicio=${requestDateInicio}`);
      } else if (state.filtroDataFim) {
        const strDateInicio = Formatter.dateToString(state.filtroDataInicio);
        const requestDateInicio = strDateInicio.split(' ')[0];
        state.filterUrlArray.push(`&data_fim=${requestDateInicio}`);
      }

      state.filterUrlString = state.filterUrlArray.join('');
      store.transferencias.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);

      store.toggleLoader();
      state.filtroRapidoEspecie = null;
    } else {
      sync();
    }
  }
  function limparFiltro(): void {
    state.filtroEspecie = null;
    state.filtroLote = null;
    state.filtroTanqueOrigem = null;
    state.filtroDataFim = null;
    state.filtroDataInicio = null;
    state.filtroRapidoEspecie = null;
    state.filterUrlArray = [];
    state.filterUrlString = '';
    store.transferencias.getPaginated(0, 25, store.propriedade!.id);
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await api
      .delete(`/transferencia/${state.selectedTransferencia?.id}/?propriedade=${store.propriedade!.id}`)
      .then(() => store.notificar('Transferência excluída com sucesso!'));
    Promise.all([
      store.transferencias.populate(),
      store.tanques.populate(),
      store.lotes.populate(),
      store.loteTanque.populate(),
    ]).then(() => {
      store.toggleLoader();
    });
    window.location.reload();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const ExcluirTransf = () => {
    const [transferenciaData, setTransferenciaData] = useState<any>(null);
    useEffect(() => {
      //eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      async function getTransferencia(transferenciaId: number) {
        const transferencia = await api.get(`/transferencia/${transferenciaId}/`, {
          params: {
            propriedade: store.propriedade!.id,
          },
        });
        setTransferenciaData(transferencia);
      }
      getTransferencia(state.selectedTransferencia?.id || 0);
    }, []);
    const loteOrigem = transferenciaData?.data.origem.codigo_lote;
    const tanqueOrigem = transferenciaData?.data.origem.tanque_nome;
    const tanqueDestino = transferenciaData?.data.destino.tanque_nome;

    const data = new Date(
      transferenciaData?.data.data.substr(3, 2) +
        '/' +
        transferenciaData?.data.data.substr(0, 2) +
        '/' +
        transferenciaData?.data.data.substr(6, 4),
    );

    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {loteOrigem && loteOrigem}
        <br />
        <b>Data: </b> {transferenciaData && Formatter.dateToString(data).substr(0, 10)}
        <br />
        <b>Origem: </b>
        {tanqueOrigem && tanqueOrigem}
        <br />
        <b>Destino: </b>
        {tanqueDestino && tanqueDestino}
        <br />
        <b>Espécie: </b>
        {loteOrigem && transferenciaData?.data.origem.especie_explorada}
        <br />
        <b>Quantidade: </b>
        {transferenciaData && Formatter.formatNumber(0, transferenciaData?.data.quantidade)}
      </div>
    );
  };

  const buscarDadosParaTabelaTransferencia = useCallback(
    async (page: number): Promise<QueryResult<any>> => {
      let data = rows([]);
      let totalCount = 0;

      if (store.propriedade) {
        try {
          await store.transferencias.getPaginated(page, 25, store.propriedade.id, state.filterUrlString);

          if (store.transferencias.totalRes) {
            if (store.transferencias.totalRes >= 50) {
              state.setPageSize(50);
            } else {
              state.setPageSize(store.transferencias.totalRes);
            }
          } else {
            state.setPageSize(0);
          }
          data = store.transferencias.arr ? rows(store.transferencias.arr) : [];
          totalCount = store.transferencias.totalRes;
        } catch (err) {
          X9Logger.report(err as Error);
          store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
        }
        
      }

      return {
        data,
        page,
        totalCount,
      };
    },
    [store.propriedade, store.transferencias, state.filterUrlString],
  );

  const recarregarTabela = async () => {
   await (tableRef.current as any).onQueryChange();
  }

  useEffect(() => { 
    buscarDadosParaTabelaTransferencia(0)
  },[buscarDadosParaTabelaTransferencia])
  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={modalCriarTransferencia.open}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={modalCriarTransferenciaPorTanque.open}
            text="POR TANQUES"
            Icon={AddIcon}
          />
          <div style={{ width: 200 }}>
            <Select
              value={state.filtroRapidoEspecie}
              placeholder="Espécie explorada"
              onChange={(e: any) => {
                state.filtroRapidoEspecie = e;
              }}
              options={store.especies.getSelectRows()!}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroEspecie}
                placeholder="Espécie explorada"
                onChange={(e: any) => (state.filtroEspecie = e)}
                options={store.especies.getSelectRows()!}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroLote}
                placeholder="Lote"
                onChange={(e: any) => (state.filtroLote = e)}
                options={store.lotes?.map((lote) => {
                  return { label: lote.codigo, value: lote.id.toString() };
                })}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                disabled={!state.filtroLote}
                value={state.filtroTanqueOrigem}
                placeholder="Tanque Origem"
                onChange={(e: any) => (state.filtroTanqueOrigem = e)}
                options={store.loteTanque.getByLote(parseInt(state.filtroLote?.value!)).map((loteTanque) => {
                  return { label: returnTanque(loteTanque.tanque), value: loteTanque.tanque.id.toString() };
                })}
              />
            </div>
            <div style={{ width: '90%' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
            </div>
            <div style={{ width: '90%' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => new Promise((resolve) => resolve(buscarDadosParaTabelaTransferencia(query.page)))}
              options={{ ...config.options, pageSize: 25 }}
              localization={config.localization}
              style={config.style}
            />
          }
        </div>
      </Body>
      {modalCriarTransferencia.state && (
        <FormTransferencia open={modalCriarTransferencia.state} handleClose={modalCriarTransferencia.close} />
      )}
      {modalCriarTransferenciaPorTanque.state && (
        <FormTransferencia
          open={modalCriarTransferenciaPorTanque.state}
          handleClose={modalCriarTransferenciaPorTanque.close}
          porTanques
        />
      )}
      {modalEditarTransferencia.state && (
        <EditarTransferencia
          open={modalEditarTransferencia.state}
          handleClose={() => {
            modalEditarTransferencia.close();
            recarregarTabela();
          }}
          transferencia={state.selectedTransferencia}
        />
      )}
      {modalVisualizarTransferencia.state && (
        <VisualizarTransferencia
          open={modalVisualizarTransferencia.state}
          handleClose={modalVisualizarTransferencia.close}
          transferencia={state.selectedTransferencia}
        />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirTransf />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Transferencia);

// ----------------------------------------------

const rows = (transferencias: Transferencia[]): object[] => {
  return transferencias
    .filter((a) => a.quantidade > 0)
    .map((transferencia) => {
      const data = new Date(
        transferencia.data.substr(3, 2) + '/' + transferencia.data.substr(0, 2) + '/' + transferencia.data.substr(6, 4),
      );
      return {
        transferencia: transferencia,
        id: transferencia.id,
        lote: transferencia.origem ? transferencia.origem.codigo_lote : '',
        especie: transferencia.origem ? transferencia.origem.especie_explorada : '',
        peso: Formatter.formatNumber(3, transferencia.peso),
        data,
        quantidade: Formatter.formatNumber(0, transferencia.quantidade),
        tqOrigem: transferencia.origem.tanque_nome
          ? transferencia.origem.tanque_nome
          : transferencia.origem.tanque_codigo,
        tqDestino: transferencia.destino.tanque_nome
          ? transferencia.destino.tanque_nome
          : transferencia.destino.tanque_codigo,
      };
    });
};
