import React, { useEffect, useState } from 'react';
import {
  Container,
  LoginBox,
  LoginButton,
  LoginSecButton,
  LoginMessage,
  ImageContainer,
  LoginContent,
  LoginFooter,
  BotaoApp,
  LoginCadastrar,
  LabelCheched,
  BotoesContainer,
  LoginChecked,
  ListaTermos,
  BotaoCadastrar,
} from './styles';
import { baseApi, api, aqRelatorios, aqReports, newApi, apiV2 } from '../../services/utils';
import useStore from '../../services/hooks/useStore';
import ModalResetarSenha from '../../components/Modais/ResetarSenha';
import { Divider, TextField } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import ShopIcon from '@material-ui/icons/Shop';
import { Flex } from '../../components/NavBar/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import useModal from '../../services/hooks/useModal';
import Formatter from '../../services/Formatter';
import CPFCNPJInput from '../../components/CPFCNPJInput';
import CadastrarClientes from '../../components/Modais/CadastrarClientes';
import { WhatsApp } from '@material-ui/icons';

const Login: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const modal = {
    recuperar: useModal(),
    cadastrar: useModal(),
  };

  const [type, setType] = useState('password');

  const state = useLocalStore(() => ({
    usuario: '',
    senha: '',

    setUsuario(e: React.ChangeEvent<HTMLInputElement>) {
      state.usuario = e.target.value;
    },
  }));

  // eslint-disable-next-line
  useEffect(store.toggleLoader, []);

  async function entrar(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    store.toggleLoader();

    const inscricaoFederal = Formatter.getNumeros(state.usuario);

    if (inscricaoFederal.length === 11 || inscricaoFederal.length === 14) {
      const loginData = {
        inscricao_federal: inscricaoFederal,
        senha: state.senha,
      };

      await baseApi
        .post('/auth/login/', loginData)
        .then(async (response) => {
          const token = response.data.device_token;

          store.token = token;

          localStorage.setItem('device_token', token);
          localStorage.setItem('device_id', JSON.stringify(response.data.device_id));

          try {
            // @ts-ignore
            api.defaults.headers.common['Authorization'] = store.token as string;
            apiV2.defaults.headers.common['Authorization'] = store.token as string;
            newApi.defaults.headers.common['Authorization'] = store.token as string;
            aqRelatorios.defaults.headers.common['Authorization'] = store.token as string;
            aqReports.defaults.headers.common['Authorization'] = store.token as string;
            newApi.defaults.headers.common['Authorization'] = store.token as string;

            const request = await api.get('/auth/me/');
            const userData: User = request.data;
            
            if (userData.plano.id === 4) {
              store.setLogado(userData);
              await store.setPropriedade(
                store.usuario.propriedades.filter((propriedade) => propriedade.ativo)[0].id,
                token,
              );
              localStorage.setItem('propriedade', store.usuario.propriedades.filter((propriedade) => propriedade.ativo)[0].id as any);
              history.push('/home');
              store.toggleLoader();
            } else {
              store.toggleLoader();
              alert('Entre em contato conosco pelo whatsapp para liberar seu plano!');
            }
          } catch (error) {
            console.log(error);
            store.toggleLoader();
            store.notificar('Ocorreu um erro no login! Tente novamente.');
          }
        })
        .catch((err) => {
          store.toggleLoader();
          err.response && store.notificar(err.response.data.error);
        });
    } else {
      store.notificar('Insira os dados corretamente');
      store.toggleLoader();
    }
  }

  return (
    <Container>
      <LoginContent>
        <img src="/images/logoHorizontal.png" alt="Logo Aquabit" width="258px" />
        <LoginBox autoComplete="on" onSubmit={entrar}>
          <CPFCNPJInput value={state.usuario} name="cpfCnpj" variant="outlined" onChange={state.setUsuario} />

          <TextField
            fullWidth
            variant="outlined"
            label="Senha"
            type={type}
            value={state.senha}
            onChange={(e) => (state.senha = e.target.value)}
            style={{ margin: '10px 0' }}
          />
          <BotoesContainer>
            <LabelCheched>
              <LoginChecked
                type="checkbox"
                onClick={() => (type === 'password' ? setType('text') : setType('password'))}
              />
              <span>Mostrar senha</span>
            </LabelCheched>
            <LoginButton type="submit" variant="contained" color="primary" style={{ marginTop: 15 }}>
              ENTRAR
            </LoginButton>
            <Flex style={{ margin: '8px 0 16px', justifyContent: 'space-between' }}>
              <LoginSecButton type="button" onClick={() => modal.recuperar.open()}>
                Esqueceu sua senha?
              </LoginSecButton>
              <LoginCadastrar secondary type="button" onClick={() => modal.cadastrar.open()}>
                Cadastre-se
              </LoginCadastrar>
            </Flex>
            <Divider />
          </BotoesContainer>
          <LoginMessage>
            <p>Você não possui uma conta ainda?</p>
            <Flex style={{ justifyContent: 'space-between' }}>
              <BotaoApp
                secondary
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/aquabit-caderno-de-manejo/id1658178676"
              >
                <AppleIcon /> App Store
              </BotaoApp>
              <BotaoApp
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.aquabitapp.aquabitApp.cadernoDeManejo"
              >
                <ShopIcon /> <p>Play Store</p>
              </BotaoApp>
            </Flex>
          </LoginMessage>
          <BotaoCadastrar
            type="button"
            style={{
              marginTop: '1rem',
              width: '100%',
              backgroundColor: '#00C853',
              border: '1px solid #00C853',
              gap: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '.5rem 0',
            }}
          >
            <a
              href="https://api.whatsapp.com/send/?phone=%2B5511950765158&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '100%',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              {' '}
              <p style={{ marginRight: '.5rem' }}>Chamar no Whatsapp!</p>
              <WhatsApp />
            </a>
          </BotaoCadastrar>
        </LoginBox>
        <LoginFooter>
          <ListaTermos
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://aquabit.com.br/termos"
                style={{
                  fontSize: '.75rem',
                }}
              >
                Termos de Uso
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://aquabit.com.br/politica-de-privacidade"
                style={{
                  fontSize: '.75rem',
                }}
              >
                Política de Privacidade
              </a>
            </li>

            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://aquabit.com.br/politica-de-cookies"
                style={{
                  fontSize: '.75rem',
                }}
              >
                Políticas de Cookies
              </a>
            </li>
          </ListaTermos>
        </LoginFooter>
      </LoginContent>

      <ImageContainer>
        <img src="/images/login_image.png" alt="Produtor usando Aquabit" />
      </ImageContainer>

      <ModalResetarSenha open={modal.recuperar.state} handleClose={() => modal.recuperar.close()} />
      <CadastrarClientes open={modal.cadastrar.state} handleClose={() => modal.cadastrar.close()} />
    </Container>
  );
};

export default observer(Login);
