/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { Body } from '../../../components/Body';
import Header from '../../../components/Header';
import AddIcon from '@material-ui/icons/Add';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import Filtro from '../../../components/Filtro';
import useStore from '../../../services/hooks/useStore';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';
import { api } from '../../../services/utils';
import Display from '../../../components/Tabela/Display';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useLocalStore, observer } from 'mobx-react-lite';
import Confirmar from '../../../components/Modais/Confirmar';
import AtualizarFase from './AtualizarFase';
import pt from 'apexcharts/dist/locales/pt.json';
import ActionButton from '../../../components/Tabela/ActionButton';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { returnTanque } from '../../../services/utils/propriedade';
import FormBiometria from './FormBiometria';
import SelecionarLote from '../../../components/Modais/SelecionarLote';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { Tooltip } from '@material-ui/core';
import FormPesoMedio from './FormPesoMedio';
import FormPesoMedioPeloCA from './FormPesoMedioPeloCA';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';

type State = PageState & {
  filtroLote: SelectRow | null;
  filtroTanque: SelectRow | null;
  rows: Biometria[];
  filtroRapidoLote: SelectRow | null;
  filtroRapidoTanque: SelectRow | null;
  lote: number | null;
  selectedBiometria: Biometria | null;

  sync: boolean;
};
const Biometrias: React.FC = () => {
  const colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];
  const tableRef = useRef();
  const [biometriasPesquisadas, setBiometriasPesquisadas] = useState<Biometria[] | undefined>(undefined);

  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(
    (): State => ({
      pageSize: 1,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      rows: [] as Biometria[],
      filtroLote: null,
      filtroTanque: null,

      filtroRapidoLote: null,
      filtroRapidoTanque: null,
      lote: null,
      selectedBiometria: null,

      sync: false,
    }),
  );
  const modalCadastro = useModal();
  const modalCadastroPesoMedio = useModal();
  const modalCadastroPeloCA = useModal();
  const modalAtualizarFase = useModal();
  const modalExclusao = useModal();
  const modalFiltroLote = useModal();
  const graficoBarra = useLocalStore(() => ({
    series: [
      {
        name: 'Biomassa (kg)',
        data: [],
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        type: 'bar',
        id: 'biomassa',
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val);
          },
        },
      },
    },

    updateData: () => {
      ApexCharts.exec('biomassa', 'updateSeries', [
        {
          data: store.lotes!.map((lote) => {
            return { x: lote.codigo, y: lote.biomassa / 1000 };
          }),
        },
      ]);
    },
  }));
  store.propriedade && store.validarPermissao(permissoes.PRODUCAO_BIOMETRIA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PRODUCAO_BIOMETRIA_ESCRITA);

  type Elemento = {
    data: string;
    historico_amostragens: Amostragem[];
    peso: number | null;
  };
  async function getHistorico(lote: Lote): Promise<Elemento[] | null> {
    if (lote) {
      let historico = await store.lotes.getHistorico(lote.id);
      historico = historico.filter((historico) => historico.tipo === 'biometria' || historico.tipo === 'povoamento');

      const datas: string[] = [...new Set(historico.reverse().map((item) => item.data))];

      const arr: Elemento[] = [];
      arr[0] = historico.filter((historico) => historico.tipo === 'povoamento')[0];

      datas.map((data) => {
        const elemento: Elemento = {
          data,
          historico_amostragens: [],
          peso: null,
        };
        const historicoData = historico.filter((item) => item.data === data && item.tipo !== 'povoamento');

        if (historicoData.length === 1) {
          elemento.historico_amostragens = historicoData[0].historico_amostragens;
          return arr.push(elemento);
        } else if (historicoData.length > 1) {
          historicoData.map((historico) =>
            historico.historico_amostragens.map((amostragem) => elemento.historico_amostragens.push(amostragem)),
          );
          return arr.push(elemento);
        } else return null;
      });

      return arr;
    } else {
      return null;
    }
  }
  const graficoLinha = useLocalStore(() => ({
    filtro: 0,
    series: [
      {
        name: 'Peso Médio (g)',
        data: [],
      },
    ],
    options: {
      title: {},
      chart: {
        locales: [pt],
        defaultLocale: 'pt',
        type: 'area',
        id: 'ganhoPeso',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val);
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
      },
    },

    updateData: async () => {
      const lote = store.lotes.get(graficoLinha.filtro || state.lote!);

      if (lote) {
        ApexCharts.exec('ganhoPeso', 'updateOptions', {
          ...graficoLinha.options,
          title: {
            text: lote.codigo,
            align: 'left',
          },
        });

        const historicoArr = await getHistorico(lote);
        ApexCharts.exec('ganhoPeso', 'updateSeries', [
          {
            data: historicoArr?.map((historico) => {
              const arr = [3, 4, 2, 0, 1, 5, 6, 7, 8, 9, 10];
              const date = arr.map((index) => historico.data[index]).join('');

              let media = 0;

              if (historico.historico_amostragens.length > 0) {
                historico.historico_amostragens.map((amostragem) => (media += amostragem.media));
                media = media / historico.historico_amostragens.length;
              } else {
                if (historico.peso) media = historico.peso;
              }

              return { x: date, y: media };
            }),
          },
        ]);
      }
    },
    filtrar: (loteID: number) => {
      graficoLinha.filtro = loteID;
      graficoLinha.updateData();
    },
  }));

  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => {
        if (!rowData.parentId) {
          return (
            <ActionButton
              editar={
                temPermissaoEscrita
                ? () => {
                  try {
                    if (rowData.props.lote.fase_lote.nome === "FINALIZADO") {
                      store.notificar('Esse lote já foi FINALIZADO!');
                      return;
                    }
                    state.selectedBiometria = rowData.props;
                    modalCadastro.open();
                  } catch (error) {
                    X9Logger.report(error as Error);
                    store.notificar('Ocorreu um erro. Por favor, tente novamente mais tarde.');
                    
                  }
                }
                : undefined
              }
              excluir={
                temPermissaoEscrita
                  ? () => {
                      state.selectedBiometria = rowData.props;
                      modalExclusao.open();
                    }
                  : undefined
              }
            />
          );
        } else return null;
      },
      cellStyle: {
        padding: 0,
      },
      ...(true && ({ width: 20 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      align: 'left',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'tanques',
      title: 'Qtde Tanques',
      render: (rowData: any) => {
        return (
          <Tooltip title={rowData.tanquesStr}>
            <span>{Formatter.formatNumber(0, rowData.tanques)}</span>
          </Tooltip>
        );
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'peso',
      type: 'numeric',
      title: 'Peso Médio (g)',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'tara',
      type: 'numeric',
      title: 'Tara (g)',
      ...(true && ({ width: 110 } as object)),
    },
    {
      field: 'pesoBruto',
      type: 'numeric',
      title: 'Peso Bruto (g)',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'quantidade',
      type: 'numeric',
      title: 'Quantidade',
      ...(true && ({ width: 120 } as object)),
    },
  ];
  async function sync(): Promise<void> {
    await syncModel.biometrias();
    state.sync = true;
  }

  // Filtro Avançado
  async function filter(): Promise<void> {
    // Checagem de filtros avançados
    if (state.filtroLote || state.filtroTanque) {
      state.filtroRapidoLote = state.filtroLote;
      state.filtroRapidoTanque = state.filtroTanque;
      (tableRef.current as any).onQueryChange();
    } else {
      (tableRef.current as any).onQueryChange();
    }
  }
  function limparFiltro(): void {
    state.filtroLote = null;
    state.filtroTanque = null;
    state.filtroRapidoLote = null;
    state.filtroRapidoTanque = null;
    setBiometriasPesquisadas(undefined);
    (tableRef.current as any).onQueryChange();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Biometrias'), []);
  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.sync && store.lotes!.length > 0) {
      graficoBarra.updateData();
      graficoLinha.updateData();
    }
    // eslint-disable-next-line
  }, [state.sync, store.biometrias]);

  // Filtro Rápido
  useEffect(() => {
    if (!state.filtroLote && !state.filtroTanque && (state.filtroRapidoLote || state.filtroRapidoTanque)) {
      (tableRef.current as any).onQueryChange();
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoLote, state.filtroRapidoTanque]);

  const ExcluirBiometria: React.FC = () => {
    const biometria = state.selectedBiometria;
    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {biometria?.lote.codigo}
        <br />
        <b>Data: </b> {biometria && Formatter.getData(biometria.data)}
        <br />
        <b>Qtde Tanques: </b> {Formatter.formatNumber(0, biometria?.lote_tanques.length)}
        <br />
        <b>Peso Médio (g): </b> {biometria && Formatter.formatNumber(3, biometria.peso_medio.media__avg)}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedBiometria = null;
              modalCadastro.open();
            }}
            text="Biometria Completa"
            Icon={AddIcon}
          />
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedBiometria = null;
              modalCadastroPesoMedio.open();
            }}
            text="Apenas Peso Médio"
            Icon={AddIcon}
          />
          <div style={{ width: 160, marginRight: 10 }}>
            <Select
              value={state.filtroRapidoLote}
              placeholder="Lote"
              onChange={(e: any) => (state.filtroRapidoLote = e)}
              options={store.lotes.getSelectRows()}
            />
          </div>
          <div style={{ width: 160 }}>
            <Select
              value={state.filtroRapidoTanque}
              placeholder="Tanque"
              onChange={(e: any) => (state.filtroRapidoTanque = e)}
              options={store.tanques.map((tanque) => {
                return { value: tanque.id.toString(), label: returnTanque(tanque) };
              })}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroLote}
                placeholder="Lote"
                onChange={(e: any) => (state.filtroLote = e)}
                options={store.lotes.getSelectRows()}
              />
            </div>

            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroTanque}
                placeholder="Tanque"
                onChange={(e: any) => (state.filtroTanque = e)}
                options={store.tanques.map((tanque) => {
                  return { label: returnTanque(tanque), value: tanque.id.toString() };
                })}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>
      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={
              biometriasPesquisadas
                ? biometriasPesquisadas
                : (query) =>
                    new Promise((resolve) => {
                      try {
                        store.propriedade &&
                          api
                            .get('/biometrias/', {
                              params: {
                                page_size: 50,
                                page: query.page + 1,
                                propriedade: store.propriedade?.id,
                                tanque: state.filtroRapidoTanque !== null ? state.filtroRapidoTanque?.value : undefined,
                                lote: state.filtroRapidoLote !== null ? state.filtroRapidoLote.value : undefined,
                              },
                            })
                            .then((res) => {
                              const rows = (biometrias: Biometria[]): object[] => {
                                const biometriasArr: object[] = [];

                                if (biometrias.length) {
                                  if (biometrias.length >= 25) {
                                    state.setPageSize(25);
                                  } else {
                                    state.setPageSize(biometrias.length);
                                  }
                                } else {
                                  state.setPageSize(1);
                                }

                                biometrias.map((biometria) => {
                                  const data = new Date(
                                    biometria.data.substr(3, 2) +
                                      '/' +
                                      biometria.data.substr(0, 2) +
                                      '/' +
                                      biometria.data.substr(6, 4),
                                  );

                                  biometriasArr.push({
                                    id: 'b' + biometria.id,
                                    lote: biometria.lote ? biometria.lote.codigo : '',
                                    data,
                                    tanques:
                                      biometria.lote_tanques.length > 0
                                        ? Formatter.formatNumber(0, biometria.lote_tanques.length)
                                        : 0,
                                    peso: Formatter.formatNumber(3, biometria.peso_medio.media__avg),
                                    tanquesStr:
                                      biometria.lote_tanques.length > 0
                                        ? biometria.lote_tanques
                                            .map((loteTanque) => {
                                              const tanque = store.tanques.get(loteTanque.tanque.id);
                                              return tanque ? returnTanque(tanque) : '';
                                            })
                                            .join(', ')
                                        : 'Sem Lote Tanques !',
                                    tara: Formatter.formatNumber(
                                      3,
                                      biometria.historico_amostragens.reduce((prev, next) => {
                                        return prev + next.tara;
                                      }, 0),
                                    ),
                                    pesoBruto: Formatter.formatNumber(3, biometria.historico_amostragens[0].peso),
                                    quantidade: Formatter.formatNumber(
                                      0,
                                      biometria.historico_amostragens[0].qtd_peixes,
                                    ),
                                    props: biometria,
                                  });

                                  // Amostragem
                                  return biometria.historico_amostragens.map((amostragem, index) => {
                                    let nomeTanqueFiltrado = '';
                                    if (amostragem?.lote_tanque) {
                                      const tanquesFiltrados = store.tanques.filter((item) => {
                                        const lotesFiltrados = biometria.lote_tanques?.filter(
                                          (item) => item.id === amostragem.lote_tanque,
                                        );

                                        if (lotesFiltrados.length > 0) {
                                          return item.id === lotesFiltrados[0]?.tanque?.id;
                                        } else {
                                          return item.id === -1;
                                        }
                                      });

                                      if (tanquesFiltrados.length > 0) nomeTanqueFiltrado = tanquesFiltrados[0]?.nome;
                                    }
                                    return biometriasArr.push({
                                      id: 'a' + amostragem.id,
                                      lote: biometria.lote ? 'Amostragem ' + (index + 1) : '',
                                      data: nomeTanqueFiltrado,
                                      peso: Formatter.formatNumber(3, amostragem.media),
                                      tara: Formatter.formatNumber(3, amostragem.tara),
                                      pesoBruto: Formatter.formatNumber(3, amostragem.peso),
                                      quantidade: Formatter.formatNumber(0, amostragem.qtd_peixes),
                                      parentId: 'b' + biometria.id,
                                    });
                                  });
                                });

                                return biometriasArr;
                              };
                              if (res.data.results.length > 0) {
                                state.lote = res.data.results.lote ? res.data.results[0].lote.id : '';
                              }
                              graficoLinha.updateData();
                              state.rows = res.data.results;

                              if (res.data.count) {
                                if (res.data.count >= 50) {
                                  state.setPageSize(50);
                                } else {
                                  state.setPageSize(res.data.count);
                                }
                              } else {
                                state.setPageSize(0);
                              }

                              try {
                              } catch (err) {}

                              resolve({
                                data: rows(res.data.results) as any,
                                page: query.page,
                                totalCount: res.data.count,
                              });
                            });
                      } catch (err) {
                        X9Logger.report(err as Error);
                        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                      }
                    })
            }
            localization={config.localization}
            style={config.style}
            options={{ ...config.options, pageSize: 50, paging: true }}
            parentChildData={(row: any, rows) => rows.find((a: any) => a.id === row.parentId)}
          />
        </div>
        <div>
          <Display style={{ marginBottom: '10px', height: 240 }} overflowY={false} title="Biomassa por lote">
            {graficoBarra && (
              <ReactApexChart
                options={graficoBarra.options as any}
                series={graficoBarra.series}
                type="bar"
                height={170}
              />
            )}
          </Display>
          <Display
            style={{ height: 250 }}
            overflowY={false}
            title="Ganho de peso por lote"
            filter={modalFiltroLote.open}
          >
            <SelecionarLote
              open={modalFiltroLote.state}
              handleClose={modalFiltroLote.close}
              onItemClick={(lote) => {
                graficoLinha.filtrar(lote.id);
              }}
              loteID={graficoLinha.filtro}
            />
            {graficoLinha && (
              <ReactApexChart
                options={graficoLinha.options as any}
                series={graficoLinha.series}
                type="area"
                height={170}
              />
            )}
          </Display>
        </div>
        {modalCadastro.state ? (
          <FormBiometria
            open={modalCadastro.state}
            handleClose={modalCadastro.close}
            onConfirm={() => {
              if (state.selectedBiometria === null) {
                modalAtualizarFase.open();

                state.selectedBiometria = null;
              }
              (tableRef.current as any)?.onQueryChange();
            }}
            biometria={state.selectedBiometria as Biometria}
          />
        ) : null}
        {modalCadastroPesoMedio.state ? (
          <FormPesoMedio
            open={modalCadastroPesoMedio.state}
            handleClose={modalCadastroPesoMedio.close}
            onConfirm={() => {
              (tableRef.current as any).onQueryChange(), modalAtualizarFase.open();
            }}
            // refresh={(tableRef.current as any)?.onQueryChange()}
          />
        ) : null}
        {modalCadastroPeloCA.state ? (
          <FormPesoMedioPeloCA
            open={modalCadastroPeloCA.state}
            handleClose={modalCadastroPeloCA.close}
            onConfirm={modalAtualizarFase.open}
          />
        ) : null}

        <Confirmar
          title="Tem certeza que deseja excluir?"
          content={<ExcluirBiometria />}
          open={modalExclusao.state}
          handleClose={modalExclusao.close}
          onConfirm={async () => {
            modalExclusao.close();
            store.toggleLoader();
            await api
              .delete(`/biometrias/${state.selectedBiometria!.id}/?propriedade=${store.propriedade!.id}`)
              .then(() => store.notificar('Biometria excluída com sucesso!'))
              .catch(() => store.notificar('Ocorreu um erro ao excluir a biometria!'));
            (tableRef.current as any).onQueryChange();
            store.toggleLoader();
          }}
          onCancel={modalExclusao.close}
        />
        {modalAtualizarFase.state ? (
          <AtualizarFase
            open={modalAtualizarFase.state}
            handleClose={modalAtualizarFase.close}
            onConfirm={() => {
              (tableRef.current as any).onQueryChange();
            }}
          />
        ) : null}
      </Body>
    </>
  ) : null;
};

export default observer(Biometrias);
