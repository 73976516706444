import { api, getThings } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';
class Mortalidades {
  @observable arr: Mortalidade[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Mortalidade>('/mortalidade/');
  }

  async getEverything(params?: object): Promise<Mortalidade[]> {
    return await getThings<Mortalidade>('/mortalidade/', params);
  }
  async getPaginated(page: number = 1, pageSize: number = 25, filterURL?: string): Promise<void> {
    const store = stateContext.state;
    const baseUrl = `/mortalidade/?propriedade=${store.propriedade!.id}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    try {
      const res = await api.get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      });

      this.totalRes = res.data.count;
      this.arr = res.data.results;
    } catch (error: any) {
      store.notificar('Erro ao buscar dados paginados. Por favor, tente novamente mais tarde.');
    }
  }

  get(id: number): Mortalidade {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByTipo(tipoID: number): Mortalidade[] {
    return this.arr!.filter((item) => item.tipo_morte.id === tipoID);
  }

  filter(callback: (item: Mortalidade, index: number, array: Mortalidade[]) => void): Mortalidade[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Mortalidade, index: number, array: Mortalidade[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Mortalidades;
